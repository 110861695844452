import {
  DELETE_AUCTION_HOUSE,
  DELETE_AUCTION_HOUSE_ERROR,
  DELETE_AUCTION_HOUSE_SUCCESS,
  GET_AUCTION_CATEGORY_LIST,
  GET_AUCTION_CATEGORY_LIST_ERROR,
  GET_AUCTION_CATEGORY_LIST_SUCCESS,
  GET_AUCTION_HOUSE,
  GET_AUCTION_HOUSE_ERROR,
  GET_AUCTION_HOUSE_SUCCESS,
} from './constants.auctionHouse';

const INIT_STATE = {
  auctionHouseData: [],
  loading: false,
  deleteAuctionLoading: false,
  errorMessage: '',
  successMesage: '',
  deleteAuctionHouseErrorMessage: '',
  deleteAuctionHouseSuccessMesage: '',
  metadata: {},
  auctionCategoryList: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AUCTION_HOUSE:
      return {
        ...state,
        loading: true,
        auctionHouseData: [],
        successMesage: '',
        errorMessage: '',
      };
    case GET_AUCTION_HOUSE_SUCCESS:
      return {
        ...state,
        auctionHouseData: [...action.payload.data],
        metadata: action.payload.metadata,
        loading: false,
        errorMessage: '',
      };
    case GET_AUCTION_HOUSE_ERROR:
      return {
        ...state,
        successMesage: '',
        errorMessage: action.payload?.message,
        loading: false,
      };
    case DELETE_AUCTION_HOUSE:
      return {
        ...state,
        deleteAuctionLoading: true,
        deleteAuctionHouseErrorMessage: '',
        deleteAuctionHouseSuccessMesage: '',
      };
    case DELETE_AUCTION_HOUSE_SUCCESS:
      return {
        ...state,
        deleteAuctionHouseSuccessMesage: action.payload?.message,
        deleteAuctionHouseErrorMessage: '',
        deleteAuctionLoading: false,
      };
    case DELETE_AUCTION_HOUSE_ERROR:
      return {
        ...state,
        deleteAuctionHouseErrorMessage: action.payload?.message,
        deleteAuctionHouseSuccessMesage: '',
        deleteAuctionLoading: false,
      };
    case GET_AUCTION_CATEGORY_LIST:
    case GET_AUCTION_CATEGORY_LIST_ERROR:
      return { ...state, auctionCategoryList: [] };
    case GET_AUCTION_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        auctionCategoryList: action?.payload?.data,
      };
    default:
      return { ...state };
  }
};
