import {
  DELETE_AUCTION_HOUSE,
  DELETE_AUCTION_HOUSE_ERROR,
  DELETE_AUCTION_HOUSE_SUCCESS,
  GET_AUCTION_CATEGORY_LIST,
  GET_AUCTION_CATEGORY_LIST_ERROR,
  GET_AUCTION_CATEGORY_LIST_SUCCESS,
  GET_AUCTION_CATEGORY_PERMISSION,
  GET_AUCTION_HOUSE,
  GET_AUCTION_HOUSE_ERROR,
  GET_AUCTION_HOUSE_SUCCESS,
  UPDATE_AUCTION_CATEGORY_PERMISSION,
  UPDATE_AUCTION_HOUSE_CURRENCY,
} from './constants.auctionHouse';

export const getAuctionHouse = (params) => {
  return {
    type: GET_AUCTION_HOUSE,
    payload: { params },
  };
};
export const getAuctionHouseSuccess = (data) => ({
  type: GET_AUCTION_HOUSE_SUCCESS,
  payload: data,
});
export const getAuctionHouseError = (message) => ({
  type: GET_AUCTION_HOUSE_ERROR,
  payload: message,
});
export const deleteAuctionHouse = (params, cb) => {
  return {
    type: DELETE_AUCTION_HOUSE,
    payload: { params, cb },
  };
};
export const deleteAuctionHouseSuccess = (data) => ({
  type: DELETE_AUCTION_HOUSE_SUCCESS,
  payload: data,
});
export const deleteAuctionHouseError = (message) => ({
  type: DELETE_AUCTION_HOUSE_ERROR,
  payload: message,
});

export const getAuctionCategoryList = (payload) => ({
  type: GET_AUCTION_CATEGORY_LIST,
  payload,
});

export const getAuctionCategoryListSuccess = (payload) => ({
  type: GET_AUCTION_CATEGORY_LIST_SUCCESS,
  payload,
});

export const getAuctionCategoryListError = () => ({
  type: GET_AUCTION_CATEGORY_LIST_ERROR,
});

export const getAuctionCategoryPermission = (payload) => ({
  type: GET_AUCTION_CATEGORY_PERMISSION,
  payload,
});

export const updateAuctionCategoryPermission = (payload) => ({
  type: UPDATE_AUCTION_CATEGORY_PERMISSION,
  payload,
});

export const updateAuctionCategoryCurrency = (payload) => ({
  type: UPDATE_AUCTION_HOUSE_CURRENCY,
  payload,
});
