/* eslint-disable no-unused-vars */

import axiosInstance from 'helpers/axiosInstance';

export async function getAuctionHouses(params) {
  try {
    const res = await axiosInstance.get('/auction-house/all-auction-houses', {
      ...params,
    });
    return res;
  } catch (err) {
    return err;
  }
}
export async function deleteAuctionHouses(auctionHouseIds) {
  try {
    const res = await axiosInstance.delete('/auction-house/delete-zone', {
      data: { auction_house_ids: auctionHouseIds },
    });
    return res;
  } catch (err) {
    return err;
  }
}

export async function getAuctionCategoryList() {
  try {
    const res = await axiosInstance.get('/auction-category-permissions');
    return res;
  } catch (err) {
    return err;
  }
}

export async function getAuctionCategory(auctionHouseId) {
  try {
    const res = await axiosInstance.get(
      `/auction-category-permissions/${auctionHouseId}`
    );
    return res;
  } catch (err) {
    return err;
  }
}

export async function updateAuctionCategory(data) {
  try {
    const res = await axiosInstance.post('/auction-category-permissions', data);
    return res;
  } catch (err) {
    return err;
  }
}

export async function updateAuctionCurrency({ id, data }) {
  try {
    const res = await axiosInstance.put(
      `/auction-house/auction-house-corrency/${id}`,
      data
    );
    return res;
  } catch (err) {
    return err;
  }
}
