export const GET_AUCTION_HOUSE = 'GET_AUCTION_HOUSE';
export const GET_AUCTION_HOUSE_SUCCESS = 'GET_AUCTION_HOUSE_SUCCESS';
export const GET_AUCTION_HOUSE_ERROR = 'GET_AUCTION_HOUSE_ERROR';

export const DELETE_AUCTION_HOUSE = 'DELETE_AUCTION_HOUSE';
export const DELETE_AUCTION_HOUSE_SUCCESS = 'DELETE_AUCTION_HOUSE_SUCCESS';
export const DELETE_AUCTION_HOUSE_ERROR = 'DELETE_AUCTION_HOUSE_ERROR';

export const GET_AUCTION_CATEGORY_PERMISSION =
  'GET_AUCTION_CATEGORY_PERMISSION';
export const GET_AUCTION_CATEGORY_PERMISSION_ERROR =
  'GET_AUCTION_CATEGORY_PERMISSION_ERROR';
export const GET_AUCTION_CATEGORY_PERMISSION_SUCCESS =
  'GET_AUCTION_CATEGORY_PERMISSION_SUCCESS';

export const GET_AUCTION_CATEGORY_LIST = 'GET_AUCTION_CATEGORY_LIST';
export const GET_AUCTION_CATEGORY_LIST_ERROR =
  'GET_AUCTION_CATEGORY_LIST_ERROR';
export const GET_AUCTION_CATEGORY_LIST_SUCCESS =
  'GET_AUCTION_CATEGORY_LIST_SUCCESS';

export const UPDATE_AUCTION_CATEGORY_PERMISSION =
  'UPDATE_AUCTION_CATEGORY_PERMISSION';
export const UPDATE_AUCTION_CATEGORY_PERMISSION_ERROR =
  'UPDATE_AUCTION_CATEGORY_PERMISSION_ERROR';
export const UPDATE_AUCTION_CATEGORY_PERMISSION_SUCCESS =
  'UPDATE_AUCTION_CATEGORY_PERMISSION_SUCCESS';

export const UPDATE_AUCTION_HOUSE_CURRENCY = 'UPDATE_AUCTION_HOUSE_CURRENCY';
