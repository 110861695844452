import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  deleteAuctionHouseError,
  deleteAuctionHouseSuccess,
  getAuctionCategoryListError,
  getAuctionCategoryListSuccess,
  getAuctionHouseError,
  getAuctionHouseSuccess,
} from './actions.auctionHouse';
import {
  DELETE_AUCTION_HOUSE,
  GET_AUCTION_CATEGORY_LIST,
  GET_AUCTION_CATEGORY_PERMISSION,
  GET_AUCTION_HOUSE,
  UPDATE_AUCTION_CATEGORY_PERMISSION,
  UPDATE_AUCTION_HOUSE_CURRENCY,
} from './constants.auctionHouse';
import {
  deleteAuctionHouses,
  getAuctionCategory,
  getAuctionCategoryList,
  getAuctionHouses,
  updateAuctionCategory,
  updateAuctionCurrency,
} from './httpCalls.auctionHouse';

export function* GetAuctionHouses(action) {
  const res = yield call(getAuctionHouses, action.payload);
  if (res.success) yield put(getAuctionHouseSuccess(res));
  else yield put(getAuctionHouseError(res));
}
export function* DeleteAuctionHouses(action) {
  const res = yield call(deleteAuctionHouses, action?.payload?.params);
  if (res.success) {
    yield put(deleteAuctionHouseSuccess(res));
  } else yield put(deleteAuctionHouseError(res));
  action?.payload?.cb?.(res);
}

export function* GetAuctionCategoryListSaga(action) {
  const res = yield call(getAuctionCategoryList);
  action?.payload?.cb?.(res);
  if (res?.success) {
    yield put(getAuctionCategoryListSuccess(res));
  } else yield put(getAuctionCategoryListError());
}

export function* GetAuctionCategoryPermissionSaga(action) {
  const { cb, params } = action.payload;
  const res = yield call(getAuctionCategory, params);
  cb?.(res);
}

export function* UpdateAuctionCategoryPermissionSaga(action) {
  const { cb, params } = action.payload;
  const res = yield call(updateAuctionCategory, params);
  cb?.(res);
}

export function* UpdateAuctionCurrencySaga(action) {
  const { cb, data, id } = action.payload;
  const res = yield call(updateAuctionCurrency, { data, id });
  cb?.(res);
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_AUCTION_HOUSE, GetAuctionHouses)],
    [yield takeEvery(DELETE_AUCTION_HOUSE, DeleteAuctionHouses)],
    [yield takeEvery(GET_AUCTION_CATEGORY_LIST, GetAuctionCategoryListSaga)],
    [
      yield takeEvery(
        GET_AUCTION_CATEGORY_PERMISSION,
        GetAuctionCategoryPermissionSaga
      ),
    ],
    [
      yield takeEvery(
        UPDATE_AUCTION_CATEGORY_PERMISSION,
        UpdateAuctionCategoryPermissionSaga
      ),
    ],
    [yield takeEvery(UPDATE_AUCTION_HOUSE_CURRENCY, UpdateAuctionCurrencySaga)]
  );
}
